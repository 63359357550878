import { AccessTypeReturn } from '@fink/dto';
import '@fontsource-variable/raleway'; // Supports weights 100-900
import '@fontsource/merriweather';
import '@fontsource/merriweather/900.css';
import { json, LinksFunction, LoaderFunctionArgs } from '@remix-run/node';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteError,
  useRouteLoaderData,
} from '@remix-run/react';
import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix';
import stylesheet from '~/tailwind.css?url';
import ErrorComponent from './components/ErrorComponent';
import { UserContextPerson, UserContextProvider } from './contexts/UserContext';
import { backendRequest, isAuthenticated } from './utils/backendRequestUtils';
import { parseCookies } from '~/utils/lib/cookies.ts';
import { AccessTokenPayload, IDTokenPayload } from '@fink/models';
import { jwtDecode } from 'jwt-decode';

export const links: LinksFunction = () => [
  { rel: 'stylesheet', href: stylesheet, as: 'style' },
];

export const loader = async ({ request }: LoaderFunctionArgs) => {
  let person = undefined;
  const authenticated = await isAuthenticated(request);
  if (!authenticated) {
    return json({ person: person });
  }

  const cookieStrings = request.headers.get('Cookie');
  const { idToken, accessToken } = await parseCookies(cookieStrings!);

  const decodedIdToken: IDTokenPayload = jwtDecode(idToken);
  const decodedAccessToken: AccessTokenPayload = jwtDecode(accessToken);

  person = { firstName: decodedIdToken.firstName };

  let detailedAccess: AccessTypeReturn[] | [] = [];
  // TODO - Add 'design-tools' || 'sms-email' || 'memberlists to this scope check when we implement the current functionality for displaying it
  if (decodedAccessToken?.scope?.includes('publish')) {
    try {
      const responseAccess = await backendRequest(
        `access/detailed-access`,
        request,
      );
      detailedAccess = responseAccess.data as AccessTypeReturn[];
      // Remove access that don't have any match/id in it, can't use it for switching access
      detailedAccess = detailedAccess?.filter((access) => {
        return access?.roleMatch || access?.orgMatch;
      });
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (_) {
      console.log('Could not get detailed access on the user');
    }
  }

  return json({
    person,
    detailedAccess,
    ENV: {
      ENABLE_SENTRY: process.env.ENABLE_SENTRY,
      SENTRY_ENVIRONMENT: process.env.SENTRY_ENVIRONMENT,
    },
  });
};

export function Layout({ children }: { children: React.ReactNode }) {
  let person: UserContextPerson | undefined;
  let detailedAccess: AccessTypeReturn[] | [];
  let ENV;

  const routeLoader = useRouteLoaderData<{
    person: UserContextPerson;
    detailedAccess: AccessTypeReturn[];
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    ENV: any;
  }>('root');

  if (routeLoader) {
    detailedAccess = routeLoader.detailedAccess;
    person = routeLoader.person;
    ENV = routeLoader.ENV;
  }

  return (
    <html lang="en" className="h-screen">
      <head>
        <title>Spir</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className="h-full">
        <UserContextProvider person={person} detailedAccess={detailedAccess}>
          {children}
        </UserContextProvider>

        {/* <Footer /> */}
        <ScrollRestoration />
        <Scripts />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(ENV)}`,
          }}
        />
      </body>
    </html>
  );
}

function App() {
  return <Outlet />;
}

export default withSentry(App);

export function ErrorBoundary() {
  const error = useRouteError();

  captureRemixErrorBoundaryError(error);

  return <ErrorComponent error={error} />;
}
