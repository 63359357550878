import { isRouteErrorResponse } from '@remix-run/react';
import logo from '~/images/logo.png';

export default function ErrorComponent({ error }: { error: unknown }) {
  const content = () => {
    if (isRouteErrorResponse(error)) {
      return (
        <>
          <h1>
            {error.status} {error.statusText}
          </h1>
          <p>{error.data}</p>
        </>
      );
    }

    return (
      <>
        <p>{(error as Error)?.message ?? 'Unknown error'}</p>
      </>
    );
  };

  return (
    <div className="flex flex-col items-center justify-center gap-4 w-full mt-40">
      <div className="">
        <img src={logo} alt="Senterpartiet logo" className="h-16 mx-1" />
      </div>
      <h1 className="text-3xl">Noe gikk galt</h1>
      {content()}
      <p className="py-2 text-sp-skog text-sm font-medium text-center">
        Du kan melde fra om feilen til{' '}
        <a
          href="mailto:spir@sp.no?subject=Tilbakemelding%20Spir%20KI&body=Skriv%20din%tilbakemelding%20her%3A%20%0A%0A"
          className="text-sp-clover-700"
        >
          spir@sp.no
        </a>
      </p>
    </div>
  );
}
